import React, { useReducer, useEffect, useState } from 'react'
import { navigate, usePath, useQueryParams } from 'hookrouter'
import { headerName } from './common/HeaderCopy'
import './App.scss'

import {
  AppContext,
  AppACTIONS,
  appReducer,
  initialAppState
} from './common/context/AppContext'
import {
  DataACTIONS,
  DataContext,
  dataReducer,
  initialDataState
} from './common/context/DataContext'
import Footer from './common/Footer'
import Header from './common/Header'
import { errorCopy } from './common/messages/MessageCopy'

import StepContent from './common/pageElements/StepContent'
import Stepper from './common/pageElements/Stepper'
import GlobalStyle from './styled-components/GlobalStyle'
import GlobalFont from './styled-components/GlobalFont'
import api from '../utils/api'
import {
  getTrimList,
  getAnalyticsUrl,
  oneTrustBanner,
  callMouseFlow,
  buildShowroomUrl,
  sanitizeUrl
} from '../utils/helper'
import { mediaQueries } from './styled-components/mediaQueries'

import 'normalize.css'

// import api from '../utils/api'
import { getAppData } from '../utils/appData'

import styled from 'styled-components'

const StyledStepView = styled.div`
  padding: ${(props) => (props.stepview ? '64px 0' : '64px 0 0 0')};
  @media ${mediaQueries.underL} {
    padding: ${(props) =>
      props.stepview
        ? '32px 16px'
        : props.isTnCPage
        ? '32px 0'
        : '64px 16px 0 16px'};
  }
`

const App = () => {
  /*
    Firstly, in order to have a dynamic context which can be passed to the consumers, we need to use the parent's state. This ensures that I've a single source of truth going forth
  */

  // /* dummy obj for local dev

  const dummyRefObj = {
    market: 'IT',
    // showroom & NGC shares same path
    application: 'NGO',
    language: 'it_it',
    exitUrl: 'www.google.com',
    brand: 'ford',
    isAuthenticated: true
    // brand: 'lincoln'
  }

  // */

  const [appState, appDispatch] = useReducer(appReducer, initialAppState)
  const [dataState, dataDispatch] = useReducer(dataReducer, initialDataState)
  const [refObj, setRefObj] = useState({})
  const [queryString] = useQueryParams()
  const [referralIdNum, setReferralId] = useState(queryString.referralId)
  const browserLang = navigator.language
  const windowUrl = window.location.hostname

  /*
    - appState contains app behavior, the appDispatch reducer updates app behavior
    - remaining values are static, as they are passed in as properties and should only need to be used like read-only data
      (these static values live at the same level as appContext, not inside of it)
  */

  // get all the languages we have
  const langSet = Object.keys(errorCopy)

  function defineLang() {
    let newLanguage = browserLang.replace('-', '_').toLowerCase()

    if (langSet.indexOf(newLanguage) < 0) {
      newLanguage = 'en_us'
    }
    if (!windowUrl.includes('.com')) {
      langSet.some((lang) => {
        if (windowUrl.slice(-2) === 'uk') {
          newLanguage = 'en_gb'
        } else if (windowUrl.slice(-2) === lang.slice(-2)) {
          newLanguage = lang
        }
      })
    }
    setRefObj({
      ...refObj,
      language: newLanguage,
      market: newLanguage.split('_')[1].toUpperCase()
    })
  }

  function getSyndicateData(brand) {
    dataDispatch({
      type: 'SHOW_LOADING_SPINNER',
      payload: true
    })
    const apiCall =
      brand === 'ford'
        ? api.getFordSyndicateData()
        : api.getLincolnSyndicateData()
    apiCall
      .then((response) => {
        const cssStyle = response.clientsideIncludes.css.trim().split('\n')
        for (let i = 0; i < cssStyle.length; i++) {
          const linkString = cssStyle[i]
            .substring(
              cssStyle[i].lastIndexOf('href='),
              cssStyle[i].indexOf('type=')
            )
            .replace('href="//', '')
            .replace('"', '')
          var link = document.createElement('link')
          link.rel = 'stylesheet'
          link.type = 'text/css'
          link.href = '//' + linkString.trim()
          document.head.appendChild(link)
        }
        // const headerContent = response.htmlModules.header
        const footerContent = response.htmlModules.footer
        const scriptContent = response.clientsideIncludes.js
        var delayInMilliseconds = 100

        setTimeout(function () {
          // document.getElementById('head').innerHTML = headerContent
          document.getElementById('foot').innerHTML = footerContent
          document.getElementById('jsscript').innerHTML = scriptContent
          dataDispatch({
            type: 'SHOW_LOADING_SPINNER',
            payload: false
          })
        }, delayInMilliseconds)
      })
      .catch((error) => {
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
        console.error(error)
      })
  }

  function getReferralDetails(referralIdNum, prefillAppraisalId) {
    if (referralIdNum) {
      api
        .getReferral(referralIdNum)
        .then((response) => {
          const { countryCode } = response
          if (response) {
            let urlRedirection = 'https://tradein.ford.'
            // if countryCode is not US and not dev/qa env
            if (
              countryCode !== 'US' &&
              windowUrl.includes('tradein.ford.com')
            ) {
              if (countryCode === 'GB') {
                urlRedirection =
                  urlRedirection + `co.uk/?referralId=${referralIdNum}`
              } else {
                urlRedirection =
                  urlRedirection +
                  countryCode.toLowerCase() +
                  `/?referralId=${referralIdNum}`
              }
              window.location.assign(urlRedirection)
            }
            if (
              !urlRedirection.includes(windowUrl) &&
              countryCode !== 'US' &&
              windowUrl.includes('tradein.ford.com')
            ) {
              window.location.assign(urlRedirection)
            }

            // // redirect to EU url
            else {
              // check if expiration date is today or future else don't save mileage
              if (
                response.appraisalExpiration >=
                new Date().toISOString().slice(0, 10)
              ) {
                dataDispatch({
                  type: DataACTIONS.deliveryMileageUpdated,
                  payload: response.deliverMileage || response.mileage
                })
              }
              parseReferral(response, prefillAppraisalId)
              dataDispatch({
                type: DataACTIONS.keysUpdated,
                payload: response.twoOrMoreKeys
              })
              dataDispatch({
                type: DataACTIONS.overallConditionUpdated,
                payload: response.overallCondition
              })
              dataDispatch({
                type: DataACTIONS.accidentsUpdated,

                payload: response.hasAccidents
              })
              dataDispatch({
                type: DataACTIONS.odorsUpdated,
                payload: response.hasOdors
              })
              dataDispatch({
                type: DataACTIONS.mechanicalDefectsUpdated,
                payload: response.hasMechanicalDefects
              })
              dataDispatch({
                type: DataACTIONS.minorDamageUpdated,
                payload: response.hasMinorDamage
              })

              dataDispatch({
                type: DataACTIONS.majorDamageUpdated,
                payload: response.hasMajorDamage
              })

              dataDispatch({
                type: DataACTIONS.warningLightsUpdated,
                payload: response.hasIlluminatedWarningLights
              })

              dataDispatch({
                type: DataACTIONS.trimOptionsUpdated,
                payload: response.trim
              })
              dataDispatch({
                type: DataACTIONS.vehicleMakeUpdated,
                payload: response.vehicleMake
              })
              dataDispatch({
                type: DataACTIONS.vehicleModelUpdated,
                payload: response.vehicleModel
              })
              dataDispatch({
                type: DataACTIONS.vehicleYearUpdated,
                payload: response.modelYear
              })

              dataDispatch({
                type: DataACTIONS.rideSharingUpdated,
                payload: response.hasProvidedServices
              })
              dataDispatch({
                type: DataACTIONS.hasMaintenanceRecordsUpdated,
                payload: response.hasMaintenanceHistory
              })

              if (countryCode === 'US') {
                oneTrustBanner(brand)
                // getSyndicateData(brand)
                callMouseFlow()

                dataDispatch({
                  type: DataACTIONS.mileageUpdated,
                  payload: response.mileage
                })

                if (!response.dtpOptIn) {
                  appDispatch({
                    type: AppACTIONS.isGuaranteedFlowUpdated,
                    payload: response.dtpOptIn
                  })
                }

                if (response.enteredBalance) {
                  dataDispatch({
                    type: DataACTIONS.enteredBalanceUpdated,
                    payload: response.enteredBalance
                  })
                }

                if (response.enteredObligation) {
                  dataDispatch({
                    type: DataACTIONS.enteredObligationUpdated,
                    payload: response.enteredObligation
                  })
                }

                //temporary condition
                if (response.color !== 'null') {
                  dataDispatch({
                    type: DataACTIONS.colorUpdated,
                    payload: response.color
                  })
                }

                dataDispatch({
                  type: DataACTIONS.remainingBalanceUpdated,
                  payload: response.remainingBalance
                })

                dataDispatch({
                  type: DataACTIONS.vinUpdated,
                  payload: response.vin
                })
                dataDispatch({
                  type: DataACTIONS.usStateUpdated,
                  payload: response.state
                })
              }
            }
          } else {
            defineLang()
          }
        })
        .catch((error) => {
          navigate('/errorInvalidRef')
          console.error(error)
          defineLang()
          setReferralId(null)
        })
    } else {
      navigate('/errorInvalidRef')
    }

    function parseReferral(response, prefillAppraisalId) {
      const {
        countryCode,
        language,
        financeType,
        url,
        deliveryDate,
        application,
        customerId,
        isAuthenticated
      } = response
      const lang = headerName.hasOwnProperty(language) ? language : 'en_us'
      document.title = headerName[lang].text
      getAppData(countryCode)
      // if its load balance url and referral url contains lincoln, it should be lincoln
      if (windowUrl.includes('ti-standalone') && url.includes('lincoln')) {
        brand = 'lincoln'
      }
      const financeTypeEU =
        financeType === 'FINANCE_TYPE_1'
          ? 'FINANCE'
          : financeType === 'FINANCE_TYPE_2'
          ? 'LEASE'
          : 'OWNED'

      let newLang
      if (!countryCode && language.includes('be')) {
        newLang = language.includes('fr') ? 'fr_fr' : 'nl_nl'
      }

      if (response.vin) {
        grecaptcha.enterprise.ready(async () => {
          api
            .vehicleLookup({
              vin: null,
              plateNumber: null,
              stateCode: response.state,
              appraisalId: response.appraisalId,
              ownershipType: response.ownershipType,
              referralId: referralIdNum,
              prefillAppraisalId
            })
            .then((data) => {
              if (!response.enteredBalance && !response.enteredObligation) {
                const usOwnershipType = response.ownershipType.toLowerCase()
                appDispatch({
                  type: AppACTIONS.financeTypeUpdated,
                  payload:
                    usOwnershipType[0].toUpperCase() + usOwnershipType.substr(1)
                })
              } else if (response.enteredBalance) {
                appDispatch({
                  type: AppACTIONS.financeTypeUpdated,
                  payload: 'Finance'
                })
              } else {
                appDispatch({
                  type: AppACTIONS.financeTypeUpdated,
                  payload: 'Lease'
                })
              }

              dataDispatch({
                type: DataACTIONS.trimOptionsUpdated,
                payload: getTrimList(data.trimOptions)
              })

              dataDispatch({
                type: DataACTIONS.appraisalIdUpdated,
                payload: data.appraisalId
              })

              dataDispatch({
                type: DataACTIONS.vehicleImageUrlUpdated,
                payload: data.vehicleImageUrl
              })
            })
            .catch((error) => {
              dataDispatch({
                type: 'SHOW_LOADING_SPINNER',
                payload: false
              })
              navigate('/vehicleNotFound')
              console.log(error)
              // pageLoadTags.errorPage('vin')
            })
          appDispatch({
            type: AppACTIONS.jumpToStepUpdated,
            payload: 'vehiclefound'
          })
          dataDispatch({
            type: 'SHOW_LOADING_SPINNER',
            payload: false
          })
          navigate('/vehiclefound')
        })
      }

      setRefObj({
        market: countryCode,
        // showroom & NGC shares same path
        application: application === 'NGC' ? 'SHOWROOM' : application,
        language: newLang || language,
        financeType: financeTypeEU,
        exitUrl: url,
        deliveryDate,
        brand,
        customerId,
        isAuthenticated
        // brand: 'lincoln'
      })
    }
  }

  let brand = windowUrl.includes('lincoln') ? 'lincoln' : 'ford'
  // let brand = 'lincoln'

  useEffect(() => {
    console.log('Access env' + process.env.ACCEPTANCE_APP_BASE_URL)
    if (referralIdNum) {
      getAnalyticsUrl(brand, windowUrl)
      getReferralDetails(referralIdNum)
    } else {
      getAnalyticsUrl(brand, windowUrl)
      defineLang()
    }
  }, [referralIdNum])

  const declineMarkets = ['FR', 'GB', 'NL', 'ES', 'IT', 'US'].includes(
    refObj.market
  )

  function resetApp(exit, appraisalId) {
    if (declineMarkets && appraisalId) {
      api
        .deleteAppraisal({ appraisalId })
        .then((data) => {
          console.log(`${refObj.market} Record Deleted`)
          handleExit(exit)
        })
        .catch((error) => {
          console.log(error)
          handleExit(exit)
        })
    } else {
      handleExit(exit)
    }
  }

  function handleExit(exit) {
    if (exit) {
      dataDispatch({
        type: 'SHOW_LOADING_SPINNER',
        payload: true
      })
      value.exitUrl
        ? window.location.assign(sanitizeUrl(value.exitUrl))
        : window.history.go(-2)
    } else {
      appDispatch({
        type: AppACTIONS.showAreYouSureModalUpdated,
        payload: false
      })
      appDispatch({
        type: AppACTIONS.stepsReset,
        payload: []
      })
      dataDispatch({ type: DataACTIONS.resetData })
      appDispatch({ type: AppACTIONS.jumpToStepUpdated, payload: 'i' })
      navigate('/')
    }
  }

  function handleApiCall(apiRoute, apiPayload) {
    api[apiRoute](apiPayload)
      .then((data) => {
        if (market === 'US') {
          window.location.assign(sanitizeUrl(refObj.exitUrl))
        } else {
          dataDispatch({
            type: 'SHOW_LOADING_SPINNER',
            payload: false
          })
          window.location.assign(sanitizeUrl(refObj.exitUrl))
        }
      })
      .catch((error) => {
        console.log(error)
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
  }

  function closeAppAndAddToCart() {
    const market = refObj.market

    let apiRoute
    let apiPayload

    dataDispatch({
      type: 'SHOW_LOADING_SPINNER',
      payload: true
    })

    if (market === 'US') {
      if (refObj.application === 'CART') {
        apiRoute = 'acceptAppraisalUS'
        apiPayload = {
          appraisalId: dataState.appraisalId,
          referralId: referralIdNum
        }
      } else {
        apiRoute = 'deleteAppraisal'
        apiPayload = {
          appraisalId: dataState.appraisalId
        }
      }

      handleApiCall(apiRoute, apiPayload)
    }
  }

  const value = {
    ...refObj,
    appState,
    appDispatch,
    browserLang,
    referralId: referralIdNum,
    resetApp,
    getReferralDetails,
    buildShowroomUrl,
    closeAppAndAddToCart
  }

  const dataValue = {
    dataState,
    dataDispatch
  }

  function getFaviconEl() {
    return document.getElementById('favicon')
  }

  const favicon = getFaviconEl() // Accessing favicon element
  favicon.href =
    brand === 'ford'
      ? './images/favicon.ico'
      : 'https://www.lincoln.com/cmslibs/etc/designs/brand_ford/brand/skin/lincoln/img/favicon.ico'

  // if not appraisal page, render padding
  const stepView = !window.location.pathname.includes('tradeinappraisal')
  const currentPath = usePath()
  const isVehicleFound = currentPath.includes('vehiclefound')
  const isTnCPage =
    currentPath.includes('termsandconditions') ||
    currentPath.includes('fusionrecalltnc')
  const market = refObj.market

  return (
    <React.Fragment>
      <GlobalFont />
      <GlobalStyle />
      {/* <div id='head' /> */}
      <div
        className={`tradein-app-wrapper-container ${brand} ${market}`}
        data-brand={brand}
      >
        <div id='tradein-tooltip-container' />
        <AppContext.Provider value={value}>
          <DataContext.Provider value={dataValue}>
            {!isTnCPage && <Header />}
            <main>
              <StyledStepView
                stepview={!isTnCPage ? stepView : false}
                isTnCPage={isTnCPage}
              >
                {!isVehicleFound && !isTnCPage && brand === 'lincoln' && (
                  <Stepper />
                )}
                <StepContent />
              </StyledStepView>
            </main>
            {!isTnCPage && <Footer />}
          </DataContext.Provider>
        </AppContext.Provider>
      </div>
      {/* <div id='foot' />
      <div id='jsscript' /> */}
    </React.Fragment>
  )
}

export default App
